//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
  switch (action.type) {
    case "UI": {
      return {
        category_parent: action.payload.category_parent,
        best_selling_products: action.payload.best_selling_products,
        brands: action.payload.brands,
        contact_us: action.payload.contact_us,
        menu: action.payload.menu,
        // original_male_shoes: action.payload.original_male_shoes,
        // original_female_shoes: action.payload.original_female_shoes,
        //original_watch: action.payload.original_watch,
        smart_watch: action.payload.smart_watch,
        //headphone: action.payload.headphone,
        // male_shoes: action.payload.male_shoes,
        // male_clothes: action.payload.male_clothes,
        // sunglasses: action.payload.sunglasses,
        sections: action.payload.sections,
        special_offer_products: action.payload.special_offer_products,
        sliders: action.payload.sliders,
        articles: action.payload.articles,
        story: action.payload.story,
        //diesel_generator: action.payload.diesel_generator,
        //alternator: action.payload.alternator,
        headphone_ariPods: action.payload.headphone_ariPods,
        speakers: action.payload.speakers,
        smart_watch_sim: action.payload.smart_watch_sim,
        chargers: action.payload.chargers,
        headphone_gaming: action.payload.headphone_gaming,
      };
    }
    default: {
      return state;
    }
  }
};
