import rubikaLogo from "../../img/elements/rubika.png";

export const DETAILS = {
  name: "فروشگاه چیچک",
  shortName: "فروشگاه چیچک",
  footerName: "چیچک",
  description: "",
  shortAbout:
    "چیچک استور یک فروشگاه معتبر است که در زمینه فروش محصولات الکترونیکی و لوازم جانبی موبایل فعالیت می ‌کند. این فروشگاه به عنوان یک منبع کامل برای انواع محصولات از جمله ساعت ‌های هوشمند، کابل‌ و شارژرهای موبایل، کارت حافظه، هولدر موبایل، اسپیکر، هدفون، هندزفری، ایرپاد، پاور بانک، رینگ لایت و دسته بازی موبایل شناخته می ‌شود. در چیچک استور، مشتریان می‌توانند از تنوع بالای محصولات الکترونیکی و لوازم جانبی موبایل بهره ‌برداری کنند.",

  veryShortAbout: "",
  link: "https://chichakstore.com",
  mobile: ["09055183424"],
  phone: ["02835233770"],
  email: [],
  postalCode: [],
  address: "",
  supportDescription: "",
  workingHours: ["10 صبح تا 8 شب"],
  mapIframeSrc: "",
  socialMedia: [
    {
      title: "ایمیل",
      value: "mohamadra807@gmail.com",
      name: "email",
      link: "mailto:mohamadra807@gmail.com",
      icon: <i className="las la-envelope"></i>,
      isPrimary: true,
    },
    {
      title: "واتس اپ",
      value: "09055183424",
      name: "whatsapp",
      link: "https://api.whatsapp.com/send/?phone=989055183424&text&app_absent=0",
      icon: <i className="lab la-whatsapp" />,
      isPrimary: true,
    },
    {
      title: "تلگرام",
      value: "chichakshap",
      name: "telegram",
      link: "https://t.me/+989055183424",
      icon: <i className="lab la-telegram-plane" />,
      isPrimary: true,
    },
    /*{
            title: 'تلگرام',
            value: 'Chichakstoore',
            name: 'telegram',
            link: 'https://t.me/Chichakstoore',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },*/

    {
      title: "روبیکا",
      value: "chichakshop",
      name: "email",
      link: "https://rubika.ir/chichakstore",
      icon: <img src={rubikaLogo} alt="logo" />,
      isPrimary: true,
    },
  ],
  services: [],
  brands: [
    /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
  ],
  enamad: {
    link: "https://trustseal.enamad.ir/?id=462215&Code=Lp5DmFggE5VvOcgW9o3mbHIC5omBEqYQ",
    src: "https://trustseal.enamad.ir/logo.aspx?id=462215&Code=Lp5DmFggE5VvOcgW9o3mbHIC5omBEqYQ",
    code: "Lp5DmFggE5VvOcgW9o3mbHIC5omBEqYQ",
  },
  samandehiLink: "",
  ZarinpalLink: "https://www.zarinpal.com/webservice/TrustCode",
};
